<template>
  <div>
    <b-row align-v="center" :class="{ 'mt-5 p-2' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" align="center">
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <title-page title="CURSOS" class="titulo"/>
      </b-col>
      <b-col cols="12" sm="12" md="12" lg="12" xl="12">
        <b-img src="img/ICONE_CONSTRUCAO.png" v-if="!imgWebp" fluid />
        <b-img src="img/ICONE_CONSTRUCAO.webp" v-if="imgWebp" fluid />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TitlePage from '../components/TitlePage.vue'
export default {
  name: 'cursos',
  components:{
      TitlePage
  },
  computed:{
    imgWebp(){
      return this.canUseWebP()
    }
  },
  methods: {
    canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      return false;
    },
  },
  created(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    document.body.style.backgroundColor = "#426B7C"
    document.body.style.backgroundImage = this.$mq !== 'xs' 
                                          && this.$mq !== 'sm' 
                                          && this.$mq !== 'md' ? 
                                            !this.imgWebp ? "url('/img/BG_CURSOS.jpg')" : "url('/img/BG_CURSOS.webp')" : 
                                            !this.imgWebp ? "url('/img/sm/BG_CURSOS.jpg')" : "url('/img/sm/BG_CURSOS.webp')"
  }
}
</script>
<style scoped>
.titulo{
  margin-bottom: 60px !important;
}
img{
  margin: auto;
  display: flex;
}
</style>